<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/mycourse/courseList?ccid=' + ccid }">课程签到</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/mycourse/signList?actId=' + actId }">签到活动</el-breadcrumb-item>
      <el-breadcrumb-item>签到环节</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="operate-container">
      <i class="el-icon-reading"></i>
      <span style="margin-left:10px;">我的课程</span>
      <div class="course-title">
        <span class="title">{{ courseName }}</span>
      </div>
    </el-card>
    <el-card class="table-container">
      <el-table :data="signData" border stripe style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column prop="linkType" label="签到环节" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.linkType == 1 ? '签到' : '签退' }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间" sortable align="center"
          v-if="signStopType == 0 || signStopType == -1">
          <template slot-scope="scope">
            <p>{{ scope.row.startTime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间" sortable align="center"
          v-if="signStopType == 0 || signStopType == -1">
          <template slot-scope="scope">
            <p>{{ scope.row.endTime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="签到时限" sortable align="center"
          v-if="signStopType == 0 || signStopType == -1">
          <template slot-scope="scope">
            <p>{{ scope.row.duration }}分钟</p>
          </template>
        </el-table-column>
        <el-table-column prop="signStopType" label="签到方式" align="center">
          <template>
            <p>{{ signStopType == 1 ? '手动控制签到' : '倒计时签到' }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="激活状态" align="center" v-if="signStopType == 0 || signStopType == -1">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 2">签到已结束</div>
            <div v-if="scope.row.status == 1">签到进行中</div>
            <el-tooltip class="item" effect="dark" content="点击激活" placement="left">
              <el-button plain v-if="scope.row.status == 0" size="mini" type="warning"
                @click="jihuo(scope.row)">未激活</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="350" label="操作" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.status | formatStatus" placement="left">
              <el-button type="success" size="mini" @click="handleCode(scope.$index, scope.row)">二维码</el-button>
            </el-tooltip>
            <el-button size="mini" type="danger" @click="handleResultOrder(scope.$index, scope.row)">签到结果</el-button>
            <el-button v-if="
              (scope.row.status == 1 || scope.row.status == 0) &&
              (signStopType == 0 || signStopType == -1)
            " size="mini" type="warning" @click="openeditTimeDia(scope.$index, scope.row)">修改时长</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper" :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize" :page-sizes="[5, 10, 15]" :total="total"></el-pagination>
      </div>
    </el-card>
    <!-- 修改时长对话框 -->
    <el-dialog title="提示" :visible.sync="editTimeDialog" width="30%">
      <el-form :model="edittimeform" style="margin-left:30px" :rules="rules" ref="edittimeform">
        <el-form-item label="修改时长:" prop="listtime">
          <el-select v-model="edittimeform.listtime" placeholder="请选择" style="width:250px">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleedittime('edittimeform')">取 消</el-button>
        <el-button type="primary" @click="edittime('edittimeform')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
const defaultFormInline = {
  pageNum: 1,
  pageSize: 10,
  university: null,
  academy: null,
  name: null
}
export default {
  name: 'signlinkList',
  data() {
    return {
      ccid: '',
      signlist: [],
      signData: [],
      formInline: {
        university: '',
        academy: '',
        name: '',
        PageNum: 1,
        PageSize: 10,
        path: 'wss://xiangqian.club:8001/ws/asset',
        socket: ''
      },
      actId: '',
      // 分页
      total: 0,
      courseName: '', //课程名
      //修改时长对话框
      editTimeDialog: false,
      //现在使用的环节
      row: null,
      edittimeform: {
        listtime: null
      },
      //表单验证信息
      rules: {
        listtime: [{ required: true, message: '请选择时长', trigger: 'change' }]
      },
      signStopType: null,
      options: [
        {
          value: 10,
          label: '+10'
        },
        {
          value: 5,
          label: '+5'
        },
        {
          value: -5,
          label: '-5'
        },
        {
          value: -10,
          label: '-10'
        }
      ]
    }
  },
  created() {
    this.ccid = window.sessionStorage.getItem('ccid')
    this.tid = window.sessionStorage.getItem('tid')
    this.actId = window.sessionStorage.getItem('actId')
    this.courseName = window.sessionStorage.getItem('title')
    this.selectList()
  },
  filters: {
    formatStatus(value) {
      if (value === 0) {
        return '待激活'
      } else if (value === 1) {
        return '签到进行中'
      } else {
        return '签到已结束'
      }
    }
  },
  methods: {
    //激活签到
    async jihuo(data1) {
      console.log('列表数据', data1)
      this.$confirm('此操作将激活该签到, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          // console.log("别看了是这里")
          const { data: res } = await this.$http.get('/act/updateLinkStatus', {
            params: {
              duration: data1.duration,
              lid: data1.lid,
              status: 1
            }
          })

          console.log(res)

          if (res.code == 200) {
            this.selectList()
            this.$message({
              message: '激活成功',
              type: 'success'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消激活'
          })
        })
    },
    //修改时长
    edittime(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post(
            '/act/updateLinkDuration',
            qs.stringify({
              lid: this.row.lid,
              duration: parseInt(this.row.duration) + this.edittimeform.listtime
            })
          )
          if (res.code == 200) {
            this.selectList()
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
          this.edittimeform.listtime = ''
          this.editTimeDialog = false
          this.$refs[formName].resetFields()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //取消修改时长
    cancleedittime(formName) {
      this.edittimeform.listtime = ''
      this.editTimeDialog = false
      this.$refs[formName].resetFields()
    },
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.PageNum = 1
      this.formInline.PageSize = val

      // 请求对应的分页数据
      this.selectList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.PageNum = val
      this.selectList()
    },

    // 重置搜索表单
    handleResetSearch() {
      this.formInline = Object.assign({}, defaultFormInline)
    },
    //打开修改时长对话框
    openeditTimeDia(index, row) {
      console.log(row)
      console.log(parseInt(row.duration))
      this.row = row
      this.editTimeDialog = true
    },

    // 跳转到签到二维码
    handleCode(index, row) {
      // window.sessionStorage.setItem('duration', row.duration)
      window.sessionStorage.setItem('lid', row.lid)
      // window.sessionStorage.setItem('linkType', row.linkType)
      this.$router.push({
        path: '/mycourse/signlinkList/signCode',
        query: {
          lid: row.lid,
          EndTime: row.endTime,
          status: row.status,
          linkType: row.linkType,
          duration: row.duration
        }
      })
    },

    // 跳转到签到结果
    handleResultOrder(index, row) {
      this.$router.push({
        path: '/mycourse/signlinkList/signResult',
        query: { lid: row.lid }
      })
    },
    // 查询签到环节
    async selectList() {
      const { data: res } = await this.$http.get('act/linkList', {
        params: {
          actid: this.actId,
          pageNum: this.formInline.PageNum,
          pageSize: this.formInline.PageSize
        }
      })
      if (res != null) {
        // console.log(res)
        this.signData = res.Link
        this.total = res.total
        this.signStopType = res.signStopType
        console.log(this.signStopType)
        window.sessionStorage.setItem('signStopType', res.signStopType)
      }
    }
  }
}
</script>

<style scoped>
.app-container {
  width: 100%;
  /* height: 100vh;
  padding-bottom: 300px;
  overflow: auto !important; */
}

.input-width {
  width: 203px;
}

.operate-container .course-title {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.operate-container .course-title .title {
  font-size: 30px;
  font-weight: bold;
}

.operate-container .course-title .time {
  font-size: 16px;
}
</style>
